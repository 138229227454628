import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { TypeAnimation } from "react-type-animation";

import axios from "axios";
import {
  deviceType,
  browserName,
  osName,
  mobileModel,
  getUA,
} from "react-device-detect";
import Cookies from "js-cookie";
import UserAgent from "user-agent";
import { v4 as uuidv4 } from "uuid";
import "./Tesa.css";

const Admin = () => {
  const ua = UserAgent.parse(navigator.userAgent);

  const HOST_NAME = "https://botapi.aspirenow.in";
  //   const HOST_NAME = "http://localhost:8000";
  const [text, setText] = useState("");
  const [reply, setReply] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [isEnd, setIsEnd] = useState(false);
  const [index, setIndex] = useState(0);
  // const [botDisplayText, setBotDisplayText] = useState("");
  const speed = 30;
  const [displayText, setDisplayText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef(null);
  const [prevData, setPrevData] = useState([
    // { role: "user", content: "Hello" },
    // {
    //   role: "assistant",
    //   content: "Hello welcome, How can I assist you to help building habits",
    // },
  ]);

  // const [reply, setReply] = useState("");
  // const [token, setToken] = useState("");
  // const [uuid, setUUID] = useState("");

  const [chat, setChat] = useState([]);

  const [count, setCount] = useState(0);
  const hasFetchedHistory = useRef(false);

  const sendReply = async () => {
    // setChat((prevChat) => [...prevChat, { user: reply }]);
    // setReply("");

    setChat((prevChat) => [
      ...prevChat,
      {
        user: reply,
        bot: "Updating memory...",
      },
    ]);

    setReply("");
    setDisplayText("");
    setIsLoading(true);
    setIndex(0);

    try {
      const res = await axios.post(`${HOST_NAME}/api/ask-datta/`, {
        question: reply,
      });

      const botReply = res.data.data; // Get the actual bot response
      console.log(botReply);

      //   setDisplayText("");
      //   if (res) {
      setIsLoading(false);

      let currentIndex = -1;
      const typingEffect = () => {
        setDisplayText((prevText) => prevText + botReply[currentIndex]);
        currentIndex += 1;

        // When the typing is complete, clear interval
        if (currentIndex >= botReply.length) {
          clearInterval(typingInterval);

          // After the typing is done, update the chat with the full response
          setChat((prevChat) => {
            const updatedChat = [...prevChat];
            updatedChat[updatedChat.length - 1] = {
              user: reply,
              bot: botReply,
            };
            return updatedChat;
          });
        }
      };

      const typingInterval = setInterval(typingEffect, speed);

      //   const typingInterval = setInterval(() => {
      //     setIndex((prevIndex) => {
      //       if (prevIndex < botReply.length) {
      //         setDisplayText((prevText) => prevText + botReply[prevIndex]);
      //         return prevIndex + 1;
      //       } else {
      //         clearInterval(typingInterval); // Stop typing animation when done
      //         return prevIndex;
      //       }
      //     });
      //   }, speed);

      //   setTimeout(() => {
      //     setChat((prevChat) => {
      //       const updatedChat = [...prevChat];
      //       updatedChat[updatedChat.length - 1] = {
      //         user: reply,
      //         bot: botReply, // Full bot response replaces "Updating memory..."
      //       };
      //       return updatedChat;
      //     });
      //   }, botReply.length * speed);
    } catch (error) {
      console.log(error);
      // Handle API error (optional)
    }
  };

  useEffect(() => {
    // Cookies.remove("deviceInfo");
    // alert(window.navigator.userAgent);
    // alert(mobileModel);
    // alert(ua.name);
    // alert(ua.os);

    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        // bottom: 0,
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    setCount((prev) => prev + 1);

    // if (index < reply.length) {
    //   const interval = setInterval(() => {
    //     setDisplayText((prev) => prev + reply[index]);
    //     setIndex((prev) => prev + 1);
    //   }, speed);
    //   return () => clearInterval(interval);
    // }

    const createUser = async () => {
      const user = uuidv4();
      await axios
        .post(`${HOST_NAME}/v2/create-user`, {
          deviceId: user,
          deviceModel: browserName,
          location: "Tamilnadu",
        })
        .then((res) => {
          // alert("New user");
          console.log("New user:", res.data.data.userData);
          Cookies.set("deviceInfo", res.data.data.userData._id);
          setDeviceId(user);
          // alert(res.data.data.userData._id);
        })
        .catch((err) => console.log(err));
    };
    // createUser();

    // setDeviceId(Cookies.get("deviceInfo"));
    const getHistory = async () => {
      await axios
        .post(`${HOST_NAME}/v2/get-history`, {
          // userId: Cookies.get("deviceInfo"),
          userId: Cookies.get("deviceInfo"),
        })
        .then((res) => {
          // alert("Existing user");
          console.log("Existing user: ", res.data);
          res.data.responses.forEach((history) => {
            console.log(history);

            setChat((prevChat) => [
              ...prevChat,
              {
                bot: history.response,
                user: history.questionName,
              },
            ]);
          });
        })
        .catch((err) => console.log(err));
    };

    if (!Cookies.get("deviceInfo")) {
      createUser();
    } else if (!hasFetchedHistory.current) {
      // Ensure getHistory only runs once
      hasFetchedHistory.current = true; // Set ref to true after the first call
      getHistory();
    }
  }, [chat]);

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      setChat((prevChat) => [
        ...prevChat,
        {
          user: reply,
          bot: "Updating memory...",
        },
      ]);

      setReply("");
      setDisplayText("");
      setIsLoading(true);
      setIndex(0);

      try {
        const res = await axios.post(`${HOST_NAME}/api/ask-datta/`, {
          question: reply,
        });

        const botReply = res.data.data;
        console.log(botReply);

        // setDisplayText("");

        setIsLoading(false);

        let currentIndex = -1;
        const typingEffect = () => {
          setDisplayText((prevText) => prevText + botReply[currentIndex]);
          currentIndex += 1;

          // When the typing is complete, clear interval
          if (currentIndex >= botReply.length) {
            clearInterval(typingInterval);

            // After the typing is done, update the chat with the full response
            setChat((prevChat) => {
              const updatedChat = [...prevChat];
              updatedChat[updatedChat.length - 1] = {
                user: reply,
                bot: botReply,
              };
              return updatedChat;
            });
          }
        };

        const typingInterval = setInterval(typingEffect, speed);

        // const typingInterval = setInterval(() => {
        //   setIndex((prevIndex) => {
        //     if (prevIndex < botReply.length) {
        //       setDisplayText((prevText) => prevText + botReply[prevIndex]);
        //       return prevIndex + 1;
        //     } else {
        //       clearInterval(typingInterval); // Stop typing animation when done
        //       return prevIndex;
        //     }
        //   });
        // }, speed);

        // setTimeout(() => {
        //   setChat((prevChat) => {
        //     const updatedChat = [...prevChat];
        //     updatedChat[updatedChat.length - 1] = {
        //       user: reply,
        //       bot: botReply, // Full bot response replaces "Updating memory..."
        //     };
        //     return updatedChat;
        //   });
        // }, botReply.length * speed);
      } catch (error) {
        console.log(error);
        // Handle API error (optional)
      }

      console.log("Chat conversations: ", chat);
      // await axios
      //   .post(`https:/apicuration.aspirenow.in/report/post-chat`, {
      //     questionName: reply,
      //     employee: "123ll",
      //     // isEnd: isEnd,
      //     // prvData: chat,
      //   })
      //   .then((res) => {
      //     // setChat((prevChat) => [
      //     //   ...prevChat,
      //     //   {
      //     //     user: reply,
      //     //     bot: res.data.data.questionResponseCreated.response,
      //     //   },
      //     // ]);
      //     setChat((prevChat) => {
      //       // Replace the last chat entry (Typing...) with the actual response
      //       const updatedChat = [...prevChat];
      //       updatedChat[updatedChat.length - 1] = {
      //         user: reply,
      //         bot: res.data.data.questionResponseCreated.response,
      //       };
      //       return updatedChat;
      //     });
      //     // setReply("");
      //   })
      //   .catch((e) => console.log(e));
      // // setChat((prevChat) => [...prevChat, { user: reply }]);
    }
  };

  //   const handleKeyDown = async (event) => {
  //     if (event.key === "Enter") {
  //       // const response = await sendChat(event.target.value);
  //       setText("");
  //       await axios
  //         .post(`${HOST_NAME}/v2/post-chat`, {
  //           questionName: event.target.value,
  //           deviceId: deviceId,
  //           isEnd: isEnd,
  //           // prvData: prevData,
  //         })
  //         .then((res) => {
  //           console.log(res.data.data.questionResponseCreated.response);

  //           const regex = /\{\s*isEnd:\s*true\s*\}/;

  //           const match = regex.test(
  //             res.data.data.questionResponseCreated.response
  //           );
  //           setDisplayText("");
  //           setIndex(0);
  //           // if (match) {
  //           //   setIsEnd(true);
  //           //   console.log("Daii");
  //           //   setReply(
  //           //     res.data.data.questionResponseCreated.response.replace(regex, "")
  //           //   );
  //           // setReply("Is this program relevant to you?");
  //           // } else {
  //           setReply(res.data.data.questionResponseCreated.response);
  //           // }

  //           // setReply(res.data.data.questionResponseCreated.response);
  //           setPrevData((prevData) => [
  //             ...prevData,

  //             {
  //               role: "user",
  //               content: res.data.data.questionResponseCreated.questionName,
  //             },
  //             {
  //               role: "assistant",
  //               content: res.data.data.questionResponseCreated.response,
  //             },
  //           ]);
  //           console.log(prevData);
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   };

  //   const convertNewlinesToBr = (text) => {
  //     return text.split("\n").join("<br />");
  //   };

  return (
    <div className="w-full h-screen flex justify-center content-center bg-gradient-to-br from-[#FEFEFE] to-[#BDD8FF]">
      <img
        className="w-32 h-auto absolute left-0"
        src={require("./aspire.png")}
      />
      <div
        style={{
          boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
        className=" pt-16 md:mt-7 bg-[rgba(255,255,255,0.1)] md:bg-[rgba(255,255,255,0.4)] p-6 rounded-lg border border-[rgba(0,0,0,0.2)] w-full h-full md:w-9/12 md:h-[90%]"
      >
        <div className="flex flex-col space-y-1.5 pb-6">
          <h2 className="font-semibold text-lg tracking-tight">
            Transforming Everyday SQL Queries
          </h2>
          <p className="text-sm text-[#6b7280] leading-3">
            Powered by <span className="font-bold">Aspire</span>
          </p>
        </div>

        <div
          id="scroll-container"
          ref={scrollRef}
          className="pr-4 h-[75%]"
          style={{
            minWidth: "100%",
            maxHeight: "80%",
            overflowY: "scroll",
          }}
        >
          <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">SQL agent </span>
              Hello I am SQL agent , I can help you to get the data related to
              Aspire App users
            </p>
          </div>
          {chat.map((ch, idx) => (
            <div>
              <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                  <div class="rounded-full bg-gray-100 border p-1">
                    <svg
                      stroke="none"
                      fill="black"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                    </svg>
                  </div>
                </span>
                <p class="leading-relaxed">
                  <span class="block font-bold text-gray-700">You </span>
                  {ch.user}
                </p>
              </div>
              {ch.bot !== undefined && (
                <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                  <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                    <div class="rounded-full bg-gray-100 border p-1">
                      <svg
                        stroke="none"
                        fill="black"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                        ></path>
                      </svg>
                    </div>
                  </span>
                  <p class="leading-relaxed">
                    <span class="block font-bold text-gray-700">
                      SQL agent{" "}
                    </span>
                    {idx === chat.length - 1 &&
                    ch.bot === "Updating memory..." ? (
                      isLoading === true ? (
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={require("./assets/thinking.gif")}
                        />
                      ) : (
                        <span>
                          {displayText.split("\n").map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))}
                        </span>
                      )
                    ) : (
                      <span>{ch.bot}</span> // Display full bot message
                    )}
                    {/* {ch.bot} */}
                  </p>
                  {/* <p className="leading-relaxed">
                    <span className="block font-bold text-gray-700">TESA </span>
                    {ch.bot === "Typing..." ? (
                      <div className="typing-indicator">
                        <span className="typing-dots"></span>
                        <span className="typing-dots"></span>
                        <span className="typing-dots"></span>
                      </div>
                    ) : (
                      ch.bot
                    )}
                  </p> */}
                </div>
              )}
            </div>
          ))}

          {/* <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>Sorry, I
              couldn't find any information in the documentation about that.
              Expect answer to be less accurateI could not find the answer to
              this in the verified sources.
            </p>
          </div>
          <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>Sorry, I
              couldn't find any information in the documentation about that.
              Expect answer to be less accurateI could not find the answer to
              this in the verified sources.
            </p>
          </div>
          <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>Sorry, I
              couldn't find any information in the documentation about that.
              Expect answer to be less accurateI could not find the answer to
              this in the verified sources.
            </p>
          </div> */}
        </div>
        <div class="flex items-center relative  mt-9 md:mt-4">
          <div class="flex items-center justify-center w-full space-x-2">
            <input
              type="text"
              value={reply}
              class="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
              placeholder="Type your message"
              onChange={(e) => setReply(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              class="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb]  bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
              onClick={() => sendReply()}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;

{
  /* <div
        style={{
          width: "100%",
          zIndex: "800",
          position: "fixed",
          backgroundColor: "black",
        }}
      >
        <img
          style={{
            width: "200px",
            height: "100px",
          }}
          src={require("./aspire.png")}
        />
      </div>
      <div
        className="chat-container"
        style={{
          padding: "20px",
          width: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // border: "1px solid #fff"
          // borderRadius: "8px",
          marginTop: "150px",
          // boxShadow: "0px 0px 8px rgba(255,255,255,0.5)",
        }}
      >
        <p
          style={{
            fontSize: "1.6em",
            display: "inline-block",
            width: "70vw",
            color: "#fff",
            zIndex: "-100",
          }}
          dangerouslySetInnerHTML={{ __html: convertNewlinesToBr(displayText) }}
        ></p>

        <textarea
          className="input-text"
          type="text"
          value={text}
          rows="5"
          onChange={(event) => setText(event.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type here.."
          style={{
            width: "70vw",
            outline: "0",
            color: "#fff",
            borderWidth: "0 0 2px",
            borderColor: "rgba(255,255,255,0.2)",
            fontSize: "1.5em",
            // lineHeight: "1px",
            marginTop: "5%",
            marginBottom: "130px",

            // paddingBottom: "10px",
            // backgroundColor: "#0042a6",
            backgroundColor: "transparent",
          }}
        />
      </div>
      <img
        style={{
          width: "98vw",
          height: "300px",
          position: "fixed",
          bottom: "-170px",
          zIndex: "10",
        }}
        src={require("./background.png")}
      /> */
}
