// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scroll-container::-webkit-scrollbar {
  width: 10px;
}
#scroll-container::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 20px;
  margin-left: 10px;
}
#scroll-container::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 10px;
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-dots {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #999;
  margin-right: 0.2em;
  opacity: 0;
  animation: typing 1.5s infinite steps(1) alternate;
}

.typing-dots:nth-child(1) {
  animation-delay: 0.1s;
}

.typing-dots:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dots:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes typing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Tesa.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,kDAAkD;AACpD;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["#scroll-container::-webkit-scrollbar {\n  width: 10px;\n}\n#scroll-container::-webkit-scrollbar-track {\n  background: #ccc;\n  border-radius: 20px;\n  margin-left: 10px;\n}\n#scroll-container::-webkit-scrollbar-thumb {\n  background-color: #eee;\n  border-radius: 10px;\n}\n\n.typing-indicator {\n  display: flex;\n  align-items: center;\n}\n\n.typing-dots {\n  display: inline-block;\n  width: 0.8em;\n  height: 0.8em;\n  border-radius: 50%;\n  background-color: #999;\n  margin-right: 0.2em;\n  opacity: 0;\n  animation: typing 1.5s infinite steps(1) alternate;\n}\n\n.typing-dots:nth-child(1) {\n  animation-delay: 0.1s;\n}\n\n.typing-dots:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.typing-dots:nth-child(3) {\n  animation-delay: 0.3s;\n}\n\n@keyframes typing {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
